import React from 'react';
import './MenuLateral.css';
// import iconoLogo from '../assets/images/iconodtm2.png';
import { Link } from 'react-router-dom';

// import {RecuperarPassModal} from '../components/RecuperarPassModal/RecuperarPassModal.js';

export default function MenuLateral({ handleMenu, onClickScrollUp }){

    React.useEffect(() => {
        window.scrollTo(0, 0);
    } , []);

    return (
        <div id="mySidenav" className="container-fluid marketPlace noPadding sidenav">

            {/* <!--LISTAS-->     */}

            <ul className="listaMarketPlace noPadding">
                <li>
                    <Link to="/desarrollo-web" className="listaMarketPlace__lista" onClick={()=>{ handleMenu(); onClickScrollUp() }}>
                        <div className="listaMarketPlace__textoMarketPlace">
                            <i className="fa-solid fa-code listaMarketPlace__icono"></i>
                            <span className="listaMarketPlace__texto">Desarrollo Web</span>
                        </div>
                        <i className="fa-solid fa-chevron-right"></i>
                    </Link>
                </li>
                <li>
                    <Link to="/marketing" className="listaMarketPlace__lista" onClick={()=>{ handleMenu(); onClickScrollUp() }}>
                        <div className="listaMarketPlace__textoMarketPlace">
                            <i className="fa-solid fa-bullhorn listaMarketPlace__icono"></i>
                            <span className="listaMarketPlace__texto">Marketing Digital</span>
                        </div>
                        <i className="fa-solid fa-chevron-right"></i>
                    </Link>
                </li>
                <li>
                    <Link to="/portafolio" className="listaMarketPlace__lista" onClick={()=>{ handleMenu(); onClickScrollUp() }}>
                        <div className="listaMarketPlace__cursos">
                            <i className="fa-solid fa-folder listaMarketPlace__icono"></i>
                            <span className="listaMarketPlace__texto">Portafolio</span>
                        </div>
                        <i className="fa-solid fa-chevron-right"></i>
                    </Link>
                </li>
                <li>
                    <Link to="/conocenos" className="listaMarketPlace__lista" onClick={()=>{ handleMenu(); onClickScrollUp() }}>
                        <div className="listaMarketPlace__membresia">
                            <i className="fa-solid fa-handshake listaMarketPlace__icono"></i>
                            <span className="listaMarketPlace__texto">Conócenos</span>
                        </div>
                        <i className="fa-solid fa-chevron-right"></i>
                    </Link>
                </li>
            </ul>
            <hr />

            {/* <!--FOOTER---> */}

            <footer className="botonMarketPlace">
                <a href="https://api.whatsapp.com/send?phone=+525538294464&text=Hola%2C%20estoy%20aqu%C3%AD%20por%20..." className="botonMarketPlace__button">
                    <div className="buttonMarketPlace">
                        <i className="fa-brands fa-whatsapp buttonMarketPlace__icono"></i>
                        <span className="buttonMarketPlace__texto">Contáctanos por WhatsApp</span>
                    </div>
                </a>
            </footer>
        </div>
    )
}