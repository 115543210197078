import React from 'react';
import './marketing.css';
import MarketinLogo from '../../assets/images/marketing.svg';
import MetaLogo from '../../assets/icons/meta-icon.png';
import AdsLogo from '../../assets/icons/ads-logo.png';
import CheckLogo from '../../assets/icons/check.png';
import AnalisisIcon from '../../assets/icons/analisis-icon.png';
import DefinicionIcon from '../../assets/icons/definicion-icon.png';
import GestionIcon from '../../assets/icons/gestion-icon.png';
import GoogleBanner from '../../assets/images/google-banner.png';
import { Link } from 'react-router-dom';

const Marketing = () => {

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  
  return (
    <div className='marketing'>
      <div className='sun__background'></div>
      <div className='container py-5'>
        <div className='marketing__head white-color' data-aos="fade-down" data-aos-duration="1300">
          <img src={MarketinLogo} alt='Marketing Logo' className='marketing__icon text-center' loading='lazy'/>
          <h1 className='fw-bold mt-5 marketing__title'>Marketing Digital</h1>
          <p className='mt-3 fs-4'>
            Potenciamos tu presencia digital mediante campañas estratégicas y dirigidas de manera directa a tu audiencia objetivo.
          </p>
        </div>
        <div className='row marketing__services g-5 my-4'>
          <div className='col service-card-detail' data-aos="fade-up" data-aos-duration="1300">
            <div className='d-flex justify-content-between align-items-center'>
              <h3 className='white-color fw-bold'>Marketing en Meta Ads</h3>
              <img src={MetaLogo} alt='Meta Logo' className='meta__icon' loading='lazy'/>
            </div>
            <p className='gray-color mt-3'>
              Alcanzamos a clientes potenciales y expandimos tu audiencia en general mediante campañas publicitarias en Facebook e Instagram.
            </p>
          </div>
          <div className='col service-card-detail' data-aos="fade-up" data-aos-duration="1300">
            <div className='d-flex justify-content-between align-items-center'>
              <h3 className='white-color fw-bold'>Search Engine Marketing (SEM)</h3>
              <img src={AdsLogo} alt='Ads Logo' className='ads__icon' loading='lazy'/>
            </div>
            <p className='gray-color mt-3'>
              Fortalecemos tu presencia digital a través de campañas estratégicas y optimización SEO en Google.
            </p>
          </div>
          <div className='col service-card-detail' data-aos="fade-up" data-aos-duration="1300">
            <div className='d-flex justify-content-between align-items-center'>
              <h3 className='white-color fw-bold'>Incluido en el servicio</h3>
              <img src={CheckLogo} alt='Check Logo' className='check__icon' loading='lazy'/>
            </div>
            <p className='gray-color mt-3'>
              Realizamos un análisis inicial de mercado, definimos estrategias, llevamos a cabo análisis de las campañas, optimizamos campañas y brindamos servicios de consultoría.
            </p>
          </div>
        </div>
        <div className='row marketing__serviceDetail g-5' >
          <div className='col text-center' data-aos="fade-up" data-aos-duration="1300">
            <img src={AnalisisIcon} alt='Analisis Icon' className='analisis__icon' loading='lazy'/>
            <h3 className='white-color fw-bold py-3 m-0'>Análisis</h3>
            <p className='gray-color'>Continuamente conocemos su mercado objetivo a través de análisis de campañas basados en pruebas y resultados.</p>
          </div>
          <div className='col text-center' data-aos="fade-up" data-aos-duration="1300">
            <img src={DefinicionIcon} alt='Definicion Icon' className='definicion__icon' loading='lazy'/>
            <h3 className='white-color fw-bold py-3 m-0'>Definición</h3>
            <p className='gray-color'>Establecemos los objetivos y las metas de su campaña en base a estudios y análisis de mercado.</p>
          </div>
          <div className='col text-center' data-aos="fade-up" data-aos-duration="1300">
            <img src={GestionIcon} alt='Gestion Icon' className='gestion__icon' loading='lazy'/>
            <h3 className='white-color fw-bold py-3 m-0'>Gestión</h3>
            <p className='gray-color'>Nos encargamos de la campaña, como el análisis, programación y modificaciones de forma semanal o mensual.</p>
          </div>
        </div>
        <div className='row marketing__contact' data-aos="fade-up" data-aos-duration="1300">
          <h2 className='fw-bold mt-5 marketing__title'>Tu negocio en todos lados.</h2>
          <div className='my-4'>
            <Link to='/contacto' className='primary__button d-inline-block'>Contáctanos</Link>
          </div>
          <div className='marketing__contact-img my-5'>
            <img src={GoogleBanner} alt='Google Banner' className='img-fluid' loading='lazy'/>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Marketing;