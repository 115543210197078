import React from 'react'
import './Contacto.css'

export default function Contacto(){
    
    React.useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    
    return(
        <div 
            id="contacto-view" 
            className="container my-5"
        >
            <div 
                className='seccion-contacto' 
                data-aos="fade-up"
            >

                {/* <img 
                    src={iconPeace} 
                    alt="peace" 
                    className="img-fluid victory-sign" 
                /> */}
                <span className='font-size-xl'>
                    🤜🏻🤛🏻
                </span>

                <h1 className="text-center fw-bold font-size-lg">
                    Trabajemos <span className="orange-color">juntos.</span>
                </h1>

                <p className="text-center dark-gray-color">
                    Deja tus datos y te contactaremos:
                </p>

                <form>

                    {/* FORMULARIO */}
                    <div className='row mb-4'>
                        <div className='col-md-6 col-12'>
                            <div className="mb-3">
                                <input 
                                    type="text" 
                                    className="form-control form-control--black" 
                                    id="nombre" 
                                    placeholder="Nombre*"
                                    autoComplete="off"
                                />
                            </div>
                        </div>
                        <div className='col-md-6 col-12'>
                            <div className="mb-3">
                                <input 
                                    type="text" 
                                    className="form-control form-control--black" 
                                    id="nombre" 
                                    placeholder="Empresa*" 
                                    autoComplete="off"
                                />
                            </div>
                        </div>
                        <div className='col-md-6 col-12'>
                            <div className="mb-3">
                                <input 
                                    type="text" 
                                    className="form-control form-control--black" 
                                    id="nombre" 
                                    placeholder="E-mail*" 
                                    autoComplete="off"
                                />
                            </div>
                        </div>
                        <div className='col-md-6 col-12'>
                            <div className="mb-3">
                                <input 
                                    type="text" 
                                    className="form-control form-control--black" 
                                    id="nombre" 
                                    placeholder="Teléfono*"   
                                    autoComplete="off"
                                />
                            </div>
                        </div>
                    </div>
                    
                    <h5 className='text-center'>
                        Cuáles de nuestros servicios son de tu interés?
                    </h5>

                    {/* OPCIONES PARA ELEGIR */}
                    <div class="checkbox-wrapper my-4">
                        <input 
                            type="checkbox" 
                            className='checkbox' 
                            id="Sitio-Web" 
                            name="Sitio-Web"
                        />
                        <label htmlFor='Sitio-Web'>
                            Sitio Web
                        </label>

                        <input 
                            type="checkbox" 
                            className='checkbox' 
                            id="E-commerce" 
                            name="E-commerce"
                        />
                        <label htmlFor='E-commerce'>
                            E-commerce
                        </label>

                        <input 
                            type="checkbox" 
                            className='checkbox' 
                            id="Landing-Page" 
                            name="Landing-Page"
                        />
                        <label htmlFor='Landing-Page'>
                            Landing Page
                        </label>

                        <input 
                            type="checkbox" 
                            className='checkbox' 
                            id="Marketing-Digital" 
                            name="Marketing-Digital"
                        />
                        <label htmlFor='Marketing-Digital'>
                            Marketing Digital
                        </label>
                    </div>

                    <button 
                        type="submit" 
                        className="btn btn-orange text-white rounded px-3 py-2"
                    >
                        Enviar
                    </button>

                </form>
            </div>
        </div>
    );
}