import React, { useEffect, useState } from 'react';

import "./TypedText.css";

const TypedText = ({ texts, typingSpeed }) => {
    const [currentText, setCurrentText] = useState('');
    const [currentCharIndex, setCurrentCharIndex] = useState(0);
    const [currentTextIndex, setCurrentTextIndex] = useState(0);
    const [isReversed, setIsReversed] = useState(false);

    useEffect(() => {
        const typeText = () => {
        const text = texts[currentTextIndex];

        if (!isReversed && currentCharIndex < text.length) {
            setCurrentCharIndex(prevIndex => prevIndex + 1);
        } else if (!isReversed) {
            // Animation forward completed, wait for 1500ms before starting the reverse animation
            setTimeout(reverseAnimation, 1000);
        } else if (currentCharIndex > 0) {
            // Animation in reverse
            setCurrentCharIndex(prevIndex => prevIndex - 1);
        } else {
            // Animation reverse completed, move to the next text in the list
            setIsReversed(false);
            setCurrentTextIndex(prevIndex => (prevIndex + 1) % texts.length);
            setCurrentCharIndex(0);
        }

        setCurrentText(text.slice(0, currentCharIndex));
        };

        const reverseAnimation = () => {
        setIsReversed(true);
        };

        const animationInterval = setInterval(typeText, typingSpeed);

        // Clean up the interval when the component is unmounted
        return () => clearInterval(animationInterval);
    }, [isReversed, currentCharIndex, currentTextIndex, texts, typingSpeed]);

    return (
        <span className="typed_text style_title">
            {currentText}
        </span>
    );
};

export default TypedText;
