import React from 'react';
import { Link } from 'react-router-dom';
import './Header.css';
import logo from '../../assets/images/logo.png';

export default function Header(props){

    return (
        <header className="conteiner-fluid cabezote fixed-top" id="header">

            <div className="cabezote__menu">
                <button className="menu__exit col-xl-0 col-lg-0" onClick={ props.handleMenu }>
                    <i className="fas fa-bars menu__icono"></i>
                    <i className="fas fa-xmark d-none menu__icono"></i>
                </button>
                <Link to="/"  className="menu__logo">
                    <img src={logo} alt="Buscalab" className="logo__imagen" />        
                    <p className="logo__texto">Busca<span className='orange-color'>lab</span></p>
                </Link>
                
            </div>

            <div className="cabezoteInfo">

                <ul className="cabezoteInfo__listaCabezote col-md-0 col-sm-0 col-xs-0">

                    <li>

                        <Link onClick={props.onClickScrollUp} to="/desarrollo-web" className="listaCabezote__link">

                            <div className="listaCabezote__referenciaCursodontic">

                                <i className="fa-solid fa-code referenciaCursodontic__icono"></i>
                                <span className="referenciaCursodontic__text">Desarrollo Web</span>

                            </div>
                        
                        </Link>

                    </li>

                    <li>

                        <Link onClick={props.onClickScrollUp} to="/marketing" className="listaCabezote__link">

                            <div className="listaCabezote__referenciaCursodontic">

                                <i className="fa-solid fa-bullhorn referenciaCursodontic__icono"></i>
                                <span className="referenciaCursodontic__text">Marketing Digital</span>

                            </div>
                        
                        </Link>

                    </li>

                    <li>

                        <Link onClick={props.onClickScrollUp} to="/portafolio" className="listaCabezote__link">

                            <div className="listaCabezote__referenciaCursodontic">

                                <i className="fa-solid fa-folder referenciaCursodontic__icono"></i>
                                <span className="referenciaCursodontic__text">Portafolio</span>

                            </div>
                        
                        </Link>

                    </li>

                    <li>

                        <Link onClick={props.onClickScrollUp} to="/conocenos" className="listaCabezote__link">

                            <div className="listaCabezote__referenciaCursodontic">

                                <i className="fa-solid fa-handshake referenciaCursodontic__icono"></i>
                                <span className="referenciaCursodontic__text">Conócenos</span>

                            </div>
                        
                        </Link>

                    </li>

                </ul>

                <Link 
                    to="/contacto" 
                    className="btn btn-orange btn-sm fw-bold"
                    onClick={ () => { props.hideMenu(); props.onClickScrollUp() } }
                >
                    Contáctanos
                </Link>

            </div>

        </header>
    );
}