import React, { useEffect, useState } from 'react';
import './portafolio.css'

import CursodonticSS from '../../assets/projects/cursodontic.jpg';
import DivumSS from '../../assets/projects/divum.png';
import HospitelecSS from '../../assets/projects/hospitelec.png';
import PromainSS from '../../assets/projects/promain.png';
import Proplay from '../../assets/projects/proplay.png';
import Card from '../../components/card/Card';

export default function Portafolio(){

    React.useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const ecommerce = [
        {
            id: 2,
            image: PromainSS,
            company: 'PROMAIN',
            description: 'Empresa dedicada a elaborar programas de mantenimiento preventivo y/o correctivo para todo tipo de instalaciones.',
            category: ['Sitio web'],
            link: 'promainindustrial.com.mx'
        },
        {
            id: 5,
            image: Proplay,
            company: 'Proplay',
            description: 'E-commerce para venta de acetatos para la fabricación de protectores bucales. ',
            category: ['Sitio web', 'E-commerce'],
            link: 'proplay.cursodontic.com',
        }
    ];

    const sitios = [
        {
            id: 1,
            image: CursodonticSS,
            company: 'Cursodontic',
            description: 'Empresa tecnológica y de educación dirigida al área dental',
            category: ['Sitio web', 'E-commerce'],
            link: 'cursodontic.com'
        },
        {
            id: 3,
            image: HospitelecSS,
            company: 'HPE',
            description: 'Plataforma para dar consultas médicas en línea, para el Hospital Privado de Ecatepec.',
            category: ['Sitio web', 'CMS'],
            link: 'hospitelec.com',
        },
        {
            id: 4,
            image: DivumSS,
            company: 'DIVUM',
            description: 'Sistema de administración hecho a la medida para una clínica dental.',
            category: ['Sitio web', 'CMS'],
            link: 'sistema.divumclinic.com',
        }
    ];

    const [scrollX, setScrollX] = useState(0);

    const [opacity, setOpacity] = useState(0);

    useEffect(() => {
        const handleScroll = () => {
        setScrollX(window.scrollY);

        const scrollPosition = window.scrollY;

        // Calcula el progreso del scroll y ajusta la opacidad
        const newOpacity = Math.min(1, scrollPosition / 300);
        setOpacity(newOpacity);
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
        window.removeEventListener('scroll', handleScroll);
        };

    }, []);

    return(
        <div className='divPortafolio'>

            <div className='sun__background' style={{ opacity }}></div>

            <div className='py-5 portafolio_title' data-aos="fade-down" data-aos-duration="1300">
                <p className='style_title text-center'>Portafolio</p>
                <p className='style_subtitle'>Conoce nuestro trabajo reciente.</p>
            </div>

            <div className='portafolio_ecommerce row' data-aos="fade-up" data-aos-duration="1300">

                <p className='style_subtitle'>Ecommerce</p>
                <div className='scroll__cards'>
                    {ecommerce.map((project) => (
                        <Card info={project} key={project.id}/>
                    ))}
                </div>

            </div>

            <div className='portafolio_sites row' data-aos="fade-up" data-aos-duration="1300">

                <p className='style_subtitle'>Sitios Web</p>
                <div className='scroll__cards'>
                    {sitios.map((project) => (
                        <Card info={project} key={project.id}/>
                    ))}
                </div>

            </div>

        </div>
    );
}