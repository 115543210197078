import React from 'react';
import './webDevelopment.css';
import ComputerLogo from '../../assets/images/dev.svg';
import SopifyLogo from '../../assets/icons/shopify-icon.png';
import WebLogo from '../../assets/icons/web-icon.png';
import LandingLogo from '../../assets/icons/landing-icon.png';
import CheckLogo from '../../assets/icons/check.png';
import Card from '../../components/card/Card';
import { Link } from 'react-router-dom';
import CursodonticSS from '../../assets/projects/cursodontic.jpg';
import DivumSS from '../../assets/projects/divum.png';
import HospitelecSS from '../../assets/projects/hospitelec.png';
import PromainSS from '../../assets/projects/promain.png';
import Proplay from '../../assets/projects/proplay.png';
import CursodonticLogo from '../../assets/images/cursodontic-blanco.png';

const WebDevelopment = () => {

    React.useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    
    // const latestProjects = [
    //     {
    //         id: 1,
    //         image: CursodonticSS,
    //         company: 'Cursodontic',
    //         description: 'Empresa tecnológica y de educación dirigida al área dental',
    //         category: ['Sitio web', 'E-commerce'],
    //         link: 'cursodontic.com'
    //     },
    //     {
    //         id: 2,
    //         image: PromainSS,
    //         company: 'PROMAIN',
    //         description: 'Empresa dedicada a elaborar programas de mantenimiento preventivo y/o correctivo para todo tipo de instalaciones.',
    //         category: ['Sitio web'],
    //         link: 'promainindustrial.com.mx'
    //     },
    //     {
    //         id: 3,
    //         image: HospitelecSS,
    //         company: 'HPE',
    //         description: 'Sistema de administración para el Hospital Privado Ecatepec.',
    //         category: ['Sitio web', 'CMS'],
    //         link: 'hospitelec.com',
    //     },
    //     {
    //         id: 4,
    //         image: DivumSS,
    //         company: 'DIVUM',
    //         description: 'Sistema de administración interna.',
    //         category: ['Sitio web', 'CMS'],
    //         link: 'sistema.divumclinic.com',
    //     },
    //     {
    //         id: 5,
    //         image: Proplay,
    //         company: 'Proplay',
    //         description: 'E-commerce para venta de acetatos para la fabricación de protectores bucales. ',
    //         category: ['Sitio web', 'E-commerce'],
    //         link: 'proplay.cursodontic.com',
    //     },
    // ];
  return (
    <div className='webDev'>
        <div className='sun__background'></div>
        <div className='container py-5'>
            <div className='marketing__head white-color' data-aos="fade-down" data-aos-duration="1300">
                <img src={ComputerLogo} alt='Marketing Logo' className='marketing__icon text-center' loading='lazy'/>
                <h1 className='fw-bold mt-5 marketing__title'>Desarrollo Web</h1>
                <p className='mt-3 fs-4'>Diseño y desarrollo de sitios web a la medida, desde e-commerce, LMS, landing pages o sitios web informativos.</p>
            </div>
            <div className='row marketing__services g-5 my-4' >
                <div className='col service-card-detail' data-aos="fade-up" data-aos-duration="1300">
                    <div className='d-flex justify-content-between align-items-center'>
                    <h3 className='white-color fw-bold'>E-commerce</h3>
                    <img src={SopifyLogo} alt='Shopify Logo' className='shopify__icon' loading='lazy'/>
                    </div>
                    <p className='gray-color mt-3'>Tu tienda personalizada diseñada y creada desde cero. Ofrecemos diseño a medida, herramientas y soluciones adaptadas a las necesidades de tu negocio para mejorar la experiencia del usuario.</p>
                </div>
                <div className='col service-card-detail' data-aos="fade-up" data-aos-duration="1300">
                    <div className='d-flex justify-content-between align-items-center'>
                    <h3 className='white-color fw-bold'>Sitio Web</h3>
                    <img src={WebLogo} alt='Web Logo' className='web__icon' loading='lazy'/>
                    </div>
                    <p className='gray-color mt-3'>Diseño y desarrollo de su sitio web personalizado. Nos conectamos con las API's que necesites para satisfacer tus necesidades.</p>
                </div>
                <div className='col service-card-detail' data-aos="fade-up" data-aos-duration="1300">
                    <div className='d-flex justify-content-between align-items-center'>
                    <h3 className='white-color fw-bold'>Landing Page</h3>
                    <img src={LandingLogo} alt='Ads Logo' className='ads__icon' loading='lazy'/>
                    </div>
                    <p className='gray-color mt-3'>Página web personalizada con información comercial y herramientas que facilitan la recolección de datos de clientes potenciales. La solución perfecta para pequeñas empresas.</p>
                </div>
                <div className='col service-card-detail' data-aos="fade-up" data-aos-duration="1300">
                    <div className='d-flex justify-content-between align-items-center'>
                    <h3 className='white-color fw-bold'>Incluido en el servicio</h3>
                    <img src={CheckLogo} alt='Check Logo' className='check__icon' loading='lazy'/>
                    </div>
                    <p className='gray-color mt-3'>Los guiaremos a través de nuestro proceso con consultoría, configuración de dominio, establecimiento de correos electrónicos personalizados, conexión con servicios de terceros y mucho más.</p>
                </div>
            </div>
            {/* <div className='row marketing__contact' data-aos="fade-up" data-aos-duration="1300">
                <h2 className='fw-bold mt-5 marketing__title'>Proyectos recientes</h2>
                <div className='scroll__cards'>
                    {latestProjects.map((project) => (
                        <Card info={project} key={project.id}/>
                    ))}
                </div>
            </div>
            <div className='text-center my-4'>
                <Link to='/portafolio' className='d-inline-block secondary__button'>Ver más proyectos</Link>
            </div> */}
            <div className='marketing__contact py-5' data-aos="fade-up" data-aos-duration="1300">
                <p className='orange-color'>Proyecto destacado</p>
                {/* <h2 className='fw-bold marketing__title mb-3'>CURSODONTIC</h2> */}
                    <a href='https://cursodontic.com/' target='_blank' rel="noreferrer" >
                        <img src={CursodonticLogo} alt='Cursodontic Logo' className='img-fluid cursodontic__logo my-4' loading='lazy'/>
                    </a>
                <div className='mb-5'>
                    <a href='https://cursodontic.com/' target='_blank' rel="noreferrer" className='d-inline-block secondary__button mx-2'>Visitar el sitio</a>
                    <Link to='/contacto' className='d-inline-block secondary__button mx-2'>Contáctanos</Link>
                </div>
                {/* <div className='webDev__iframe'>
                    <iframe title='cursodontic' className='' src="https://cursodontic.com/" width="500" height="500" frameborder="0"></iframe>
                </div> */}
            </div>
        </div>
    </div>
  )
}

export default WebDevelopment;