import React from 'react'
import './Footer.css'
import { Link } from 'react-router-dom'

export default function Footer(props){
    return (
        <footer 
            className="container-fluid my-3" 
            data-aos="fade-up"
        >
            
            <div className='text-white d-flex justify-content-between p-md-5 p-2 flex-md-row flex-column'>

                <div 
                    className="d-flex align-items-center px-4 py-1 rounded-5 bg-dark justify-content-evenly" 
                    style={{height:100}}
                >
                    <h5 className='m-0'>
                        Trabajemos juntos...
                    </h5>

                    <Link
                        onClick={props.onClickScrollUp}
                        to="/contacto"
                        className="btn btn-dark px-3 py-2"
                    >
                        Contáctanos
                    </Link>
                </div>

                <div className="d-flex flex-column bg-dark-gray justify-content-md-center justfy-content-center mt-md-0 mt-5">

                    <div className="text-center">
                        <h5 className="m-0">
                            Nuestros servicios:
                        </h5>
                        <Link 
                            onClick={props.onClickScrollUp}
                            to="/marketing" 
                            className="btn btn-dark px-3 py-2"
                        >
                            Marketing Digital
                        </Link>
                        <Link 
                            onClick={props.onClickScrollUp}
                            to="/desarrollo-web" 
                            className="btn btn-dark px-3 py-2"
                        >
                            Desarrollo Web
                        </Link>
                    </div>

                    <div className="mt-4 text-center secondary-font">
                        <small className="fw-bold">
                            ©2024, <span className='gray-color'>Busca</span><span className='orange-color'>lab</span>.
                        </small>
                    </div>
                </div>

            </div>

        </footer>
    )
}