import React, { useEffect, useState } from 'react';

import TypedText from '../../components/TypedText/TypedText.js';

import "./home.css";

import cursodontic from '../../assets/images/cursodontic-blanco.png';
import divum from '../../assets/images/divum.png';
import hospitelec from '../../assets/images/hospitelec-blanco.png';
import promain from '../../assets/images/promain.png';
import proplay from '../../assets/images/proplay-blanco.png';
import computadora from '../../assets/icons/computer-icon.png';
import marketing from '../../assets/icons/marketing-logo.png';
import Card from '../../components/card/Card';
import { Link } from 'react-router-dom';
import CursodonticSS from '../../assets/projects/cursodontic.jpg';
import DivumSS from '../../assets/projects/divum.png';
import HospitelecSS from '../../assets/projects/hospitelec.png';
import PromainSS from '../../assets/projects/promain.png';
import Proplay from '../../assets/projects/proplay.png';
import mainMockup from '../../assets/images/main-mockup.png';

const Home = () => {

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const texts = [
    "Soluciones Digitales.",
    "Desarrollo Web.",
    "Marketing Digital."
  ];

  const latestProjects = [
    {
        id: 1,
        image: CursodonticSS,
        company: 'Cursodontic',
        description: 'Empresa tecnológica y de educación dirigida al área dental.',
        category: ['Sitio web', 'E-commerce'],
        link: 'cursodontic.com'
    },
    {
        id: 2,
        image: PromainSS,
        company: 'PROMAIN',
        description: 'Empresa dedicada a elaborar programas de mantenimiento preventivo y/o correctivo para todo tipo de instalaciones.',
        category: ['Sitio web'],
        link: 'promainindustrial.com.mx'
    },
    {
        id: 3,
        image: HospitelecSS,
        company: 'HPE',
        description: 'Plataforma para dar consultas médicas en línea, para el Hospital Privado de Ecatepec.',
        category: ['Sitio web', 'CMS'],
        link: 'hospitelec.com',
    },
    {
        id: 4,
        image: DivumSS,
        company: 'DIVUM',
        description: 'Sistema de administración hecho a la medida para una clínica dental.',
        category: ['Sitio web', 'ERP'],
        link: 'sistema.divumclinic.com',
    },
    {
        id: 5,
        image: Proplay,
        company: 'Proplay',
        description: 'E-commerce para venta de acetatos para la fabricación de protectores bucales. ',
        category: ['Sitio web', 'E-commerce'],
        link: 'proplay.cursodontic.com',
    },
  ];

  const typingSpeed = 60; // Adjust the typing speed (in milliseconds) here

  const [scrollX, setScrollX] = useState(0);

  const [opacity, setOpacity] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      setScrollX(window.scrollY);

      const scrollPosition = window.scrollY;

      // Calcula el progreso del scroll y ajusta la opacidad
      const newOpacity = Math.min(1, scrollPosition / 800);
      setOpacity(newOpacity);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };

  }, []);

  return (
    <div className="divPrincipal">

      <div className='sun__background' style={{ opacity }}></div>

      <div className="parallax_container">

        <div>
          <div className="banner_text container">
            <p className='style_title'>Potenciamos tu negocio a través de</p>
            <TypedText texts={texts} typingSpeed={typingSpeed} />
            <br />
            <p className="banner_subtext rounded-3">
              Nuestra misión es potenciar el éxito de tu negocio a través de soluciones altamente eficientes, impulsando un crecimiento exponencial en la era digital.
            </p>
          </div>

          <div className='div_image_banner'>

            <img className='image_banner' src={mainMockup} alt="banner" style={{ transform: `translateX(-${scrollX * 0.5}px)` }}/>

          </div>
        </div>

      </div>

      <div className="horizontal-scroll-container" data-aos="zoom-in">
        <div className="horizontal-scroll-container-large">
          <div className="horizontal_scroll_container_content" id="content_scroll_container">
            <img src={cursodontic} alt="cursodontic" />
            <img src={divum} alt="divum" />
            <img src={hospitelec} alt="hospitelec" />
            <img src={promain} alt="promain" />
            <img src={proplay} alt="proplay" />
          </div>
        </div>
      </div>


      <div className="cover_container text-center container" data-aos="fade-up" data-aos-duration="1300">
        <p className='style_title'>Te tenemos cubierto.</p>
        <p className="cover_container_text">
          Enfocados en diseñar y desarrollar soluciones digitales de vanguardia, nuestro objetivo es potenciar el crecimiento de tu empresa y llegar a un mayor número de clientes en línea.
        </p>
      </div>


      <div className="services_container container">

        <div className="row">

          <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">

            <div className="service_card" data-aos="zoom-in-up">

              <div className="service_card_content">

                <img src={computadora} loading="lazy" sizes="(max-width: 767px) 80px, (max-width: 991px) 90px, 100px" alt="" className="service_card_content_icon" />

                <h2 className='style_subtitle'>Desarrollo Web</h2>
                <p className="cover_container_text">

                  Diseño y desarrollo de sitios web a la medida desde e-commerce, LMS, landing pages o sitios web informativos para tus necesidades específicas.
                </p>
                <Link className='btn-secondary' to='/desarrollo-web'>
                  Conoce más
                </Link>

              </div>

            </div>

          </div>


          <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">

            <div className="service_card" data-aos="zoom-in-up">

              <div className="service_card_content">

                <img src={marketing} loading="lazy" sizes="(max-width: 767px) 80px, (max-width: 991px) 90px, 100px" alt="" className="service_card_content_icon" />

                <h2 className='style_subtitle'>Marketing Digital</h2>
                <p className="cover_container_text">
                  Amplifica tu presencia digital mediante campañas en Facebook, Instagram, Youtube o buscador de Google.
                </p>
                <Link className='btn-secondary' to='/marketing'>
                  Conoce más
                </Link>

              </div>

            </div>

          </div>

        </div>

      </div>


      <div className="container">
        <div className="row container_methodology" data-aos="fade-up">

          <div className="col-12 col-sm-12 col-md-7 col-lg-7 col-xl-7 my-auto">

            <p className='style_title container_methodology_title'>Nuestra metodología innovadora es el motor que impulsa nuestro proceso creativo.</p>

            <Link className='btn-primary' to='/conocenos'>
              Conoce nuestro proceso
            </Link>

          </div>

          <div className="col-0 col-sm-0 col-md-5 col-lg-5 col-xl-5 px-sm-2 px-md-2 px-5">

            <div>
              <p className="container_methodology_points">1. Explorar</p>
              <p className="container_methodology_text">Conocemos el negocio, su identidad, mercado objetivo y objetivos a corto, mediano y largo plazo.</p>
            </div>
            <div className="my-5">
              <p className="container_methodology_points">2. Definir</p>
              <p className="container_methodology_text">Trabajamos en conjunto para definir los objetivos y la visión del proyecto, creando estrategias para cumplirlas.</p>
            </div>
            <div>
              <p className="container_methodology_points">3. Implementar</p>
              <p className="container_methodology_text">Comenzamos a construir las soluciones basado en las etapas y definición del proyecto.</p>
            </div>

          </div>

        </div>
      </div>


      <div className='container_proyects_carousel row' data-aos="fade-up" data-aos-duration="1300">

        <p className='style_title text-center'>Proyectos destacados</p>
        <div className='scroll__cards'>
            {latestProjects.map((project) => (
                <Card info={project} key={project.id}/>
            ))}
        </div>

      </div>

      <div className='container_proyects' data-aos="fade-up" data-aos-duration="1300">

        <Link to='/portafolio' className='btn-secondary'>Ver más proyectos</Link>

      </div>


    </div>
  );
};

export default Home;
