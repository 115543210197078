import { Routes, Route, Navigate } from 'react-router-dom';
import Home from './pages/Home/Home';
import Marketing from './pages/Marketing/Marketing';
import Contacto from './pages/Contacto';
import Portafolio from './pages/Portafolio/Portafolio.js';
import Footer from './components/Footer';
import Header from './components/Header';
import MenuLateral from './components/MenuLaterial';
import $ from 'jquery'
import WebDevelopment from './pages/WebDevelopment/WebDevelopment';
import Conocenos from './pages/Conocenos';

function App() {

  const onClickScrollUp = () => {
    window.scrollTo(0, 0);
  }

  const hideMenu = () => {
    $(".menu__icono.fa-bars").removeClass("d-none");
    $(".menu__icono.fa-xmark").addClass("d-none");

    document.getElementById("mySidenav").style.width = "0";
    $("#mySidenav").removeClass("active");
  }

  const showMenu = () => {
    $(".menu__icono.fa-bars").addClass("d-none");
    $(".menu__icono.fa-xmark").removeClass("d-none");

    document.getElementById("mySidenav").style.width = "100%";
    $("#mySidenav").addClass("active");
  }

  const handleMenu = () => {
    if( $(".menu__icono.fa-bars").hasClass("d-none") ){
      hideMenu()
    } else {
      showMenu()
    }
  }

  return (
    <>

      <Header 
        handleMenu={handleMenu}
        hideMenu={hideMenu}
        onClickScrollUp={onClickScrollUp}
      />
      <div style={{height:56}}/>

      <MenuLateral 
        handleMenu={handleMenu}
        onClickScrollUp={onClickScrollUp}
      />

      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/marketing" element={<Marketing />} />
        <Route path="/contacto" element={<Contacto />} />
        <Route path="/conocenos" element={<Conocenos />} />
        <Route path="/desarrollo-web" element={<WebDevelopment />} />
        <Route path="/portafolio" element={<Portafolio />} />
        <Route
          path="*"
          element={<Navigate to="/" replace />}
        />
      </Routes>

      <hr className='text-white'/>
      <Footer
        onClickScrollUp={onClickScrollUp}
      />
    </>
  );
}

export default App;
