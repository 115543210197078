import React from 'react';

export default function PreguntaFrecuente({ pregunta, respuesta, index }) {
    return(
        <div className="col-12">
            <div className="preguntas__card rounded">
                <div className="card-header border-0" id={`heading${index}`}>
                    <button className="btn text-reset txt-rojo btn-block text-center preguntas__botonCard" type="button" data-bs-toggle="collapse" data-bs-target={`#collapse${index}`} aria-expanded="true" aria-controls={`collapse${index}`}>
                        { pregunta }
                        <i className="fas fa-chevron-down"></i>
                    </button>
                </div>

                <div id={`collapse${index}`} className="collapse text-white" aria-labelledby={`heading${index}`} data-bs-parent="#preguntas__elementos">
                    <div className="card-body p-3 text-left">
                        { respuesta }
                    </div>
                </div>
            </div>
        </div>
    )
}
