import React from 'react';
import './card.css';

const Card = ({info}) => {

  return (
    <div className='service-card-detail card'>
        {/* <img src='https://via.placeholder.com/1080x720' alt='' className='img-featured' loading='lazy'/> */}
        <img src={info.image} alt={info.company} className='img-featured' loading='lazy'/>
        <div className='card__body'>
            <h3 className='white-color'>{info.company}</h3>
            <a href={`http://${info.link}`} target='_blank' rel="noreferrer">{info.link}</a>
            <p className='gray-color mt-3 card__description'>{info.description}</p>
        </div>
        <div className='d-flex align-items-center'>
            {info.category.map((category, index) => (
                <p key={index} className={`white-color mx-2 card__category ${(index > 0) && 'orange'}`}>{category}</p>
            ))}
        </div>
    </div>
  )
}

export default Card;